export const SET_USER = "SET_USER";
export const LOGOUT_USER = "LOGOUT_USER";
export const TOGGLE_MENU = "TOGGLE_MENU";
export const ADD_ERROR = "ADD_ERROR";
export const AXIOS_REQUEST_SENT = "AXIOS_REQUEST_SENT";
export const AXIOS_RESPONSE_RECEIVED = "AXIOS_RESPONSE_RECEIVED";
export const CLEAR_ERRORS = "CLEAR_ERRORS";
export const SET_FAVORITES = "SET_FAVORITES";
export const DELETE_FAVORITE = "DELETE_FAVORITE";
export const SET_STATIONS = "SET_STATIONS";
export const SET_STATION = "SET_STATION";
export const SET_AMENITIES = "SET_AMENITIES";
export const DELETE_REVIEW = "DELETE_REVIEW"
